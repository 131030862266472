/* eslint-disable deprecation/deprecation */
import * as React from 'react';

import { useState } from 'react';

import { styled, Theme, useTheme } from '@mui/material/styles';
import { css } from '@emotion/react';

import {
  Button,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Container,
  Typography,
  AppBar,
  Box,
  Hidden,
  Toolbar,
  ListItemButton,
  ListItemButtonProps,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import GavelIcon from '@mui/icons-material/Gavel';
import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work';
import MicIcon from '@mui/icons-material/Mic';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

import LogoImg from '../../assets/images/cgx_logo.svg';

import { MakeLinkRef, LinkBehavior } from './Link';
import { sectionForLocation, SiteSection } from '../routing';

const docsLocations: SiteSection[] = [
  'docsIndex',
  'fix',
  'userManual',
  'marketStructure',
];

type HideableNavProps = {
  children?: React.ReactNode;
  hideLogo?: boolean;
};

type PathAwareNavProps = {
  location: Location;
};

type NavProps = HideableNavProps & PathAwareNavProps;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(0),
}));

const LogoWrapper = styled(Box)({
  display: 'flex',
  flexGrow: 1,
});

const Logo = styled('img')(({ theme }) => ({
  maxHeight: '45px',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    maxHeight: '65px',
  },
}));

const LogoText = styled(Typography)(({ theme }) => ({
  display: 'none',
  paddingLeft: theme.spacing(0.2),
  color: theme.palette.primary.dark,
  [theme.breakpoints.up('md')]: {
    display: 'block',
    fontSize: '2rem',
  },
}));

const NavWrapper: React.FunctionComponent<HideableNavProps> = ({
  children,
  hideLogo = false,
}) => {
  return (
    <StyledToolbar id="toolbar-menu">
      {!hideLogo && (
        <LogoWrapper alignItems="center">
          <Logo src={LogoImg} alt="OneChronos logo" />
          <LogoText variant="subtitle1">OneChronos</LogoText>
        </LogoWrapper>
      )}
      {children}
    </StyledToolbar>
  );
};

const linkText = (theme: Theme, isActive: boolean) => css`
  min-width: ${theme.spacing(2)};
  color: ${theme.palette.primary.main};
  :active {
    color: ${theme.palette.text.secondary};
  }
  ${theme.breakpoints.up('md')} {
    min-width: ${theme.spacing(4)};
  }
  ${isActive ? `background-color: ${theme.palette.grey[300]};` : ''}
`;

const NavLinks: React.FunctionComponent<PathAwareNavProps> = ({ location }) => {
  const theme = useTheme();

  return (
    <>
      <Button
        css={linkText(theme, sectionForLocation(location) === 'home')}
        component={LinkBehavior}
        to="/"
      >
        Home
      </Button>
      <Button
        css={linkText(
          theme,
          sectionForLocation(location) === 'expressiveBidding'
        )}
        component={LinkBehavior}
        to="/docs/expressive/"
      >
        E-Bidding
      </Button>
      <Button
        css={linkText(
          theme,
          docsLocations.includes(sectionForLocation(location) || 'home')
        )}
        component={LinkBehavior}
        to="/docs/"
      >
        Docs
      </Button>
      <Button
        css={linkText(theme, sectionForLocation(location) === 'about')}
        component={LinkBehavior}
        to="/about/"
      >
        About
      </Button>
      <Button
        css={linkText(theme, sectionForLocation(location) === 'insights')}
        component={LinkBehavior}
        to="https://think.onechronos.com/podcast/"
      >
        Insights
      </Button>
      <Button
        css={linkText(theme, sectionForLocation(location) === 'careers')}
        component={LinkBehavior}
        to="/careers/"
      >
        Careers
      </Button>
      <Button
        css={linkText(theme, sectionForLocation(location) === 'contact')}
        component={LinkBehavior}
        to="/contact/"
      >
        Contact
      </Button>
    </>
  );
};

const NavExpanded: React.FunctionComponent<NavProps> = ({
  location,
  hideLogo,
}) => (
  <NavWrapper hideLogo={hideLogo}>
    <NavLinks location={location} />
  </NavWrapper>
);

const LinkText = styled(ListItemText)(({ theme }) => linkText(theme, false));

const ListItemLink: React.FunctionComponent<
  {
    key: string;
    icon: React.JSX.Element;
    primary: string;
    to: string;
    selected?: boolean;
  } & ListItemButtonProps
> = (props) => {
  const { icon, primary, to } = props;
  const CustomLink = React.useMemo(() => MakeLinkRef(to), [to]);

  return (
    <li>
      <ListItemButton selected={props.selected} component={CustomLink}>
        <ListItemIcon>{icon}</ListItemIcon>
        <LinkText primary={primary} />
      </ListItemButton>
    </li>
  );
};

const ListItemExternalLink: React.FunctionComponent<
  {
    key: string;
    icon: React.JSX.Element;
    primary: string;
    to: string;
  } & ListItemButtonProps
> = (props) => {
  const { icon, primary, to } = props;
  return (
    <li>
      <ListItemButton selected={props.selected} component="a" href={to}>
        <ListItemIcon>{icon}</ListItemIcon>
        <LinkText primary={primary} />
      </ListItemButton>
    </li>
  );
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const NavCollapse: React.FunctionComponent<NavProps> = ({
  location,
  hideLogo,
}) => {
  const [state, setState] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState(open);
    };

  return (
    <NavWrapper hideLogo={hideLogo}>
      <StyledIconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        size="large"
      >
        <MenuIcon className="side-menu-toggle" />
      </StyledIconButton>
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItemLink
              key="home"
              icon={<HomeIcon />}
              primary="Home"
              selected={sectionForLocation(location) === 'home'}
              to="/"
            />
            <ListItemLink
              key="bidding"
              icon={<GavelIcon />}
              primary="E-Bidding"
              selected={sectionForLocation(location) === 'expressiveBidding'}
              to="/docs/expressive/"
            />
            <ListItemLink
              key="docs"
              icon={<DescriptionIcon />}
              primary="Docs"
              selected={docsLocations.includes(
                sectionForLocation(location) || 'home'
              )}
              to="/docs/"
            />
            <ListItemLink
              key="about"
              icon={<InfoIcon />}
              primary="About"
              selected={sectionForLocation(location) === 'about'}
              to="/about/"
            />
            <ListItemExternalLink
              key="insights"
              icon={<MicIcon />}
              primary="Insights"
              to="https://think.onechronos.com/podcast/"
            />
            <ListItemLink
              key="careers"
              icon={<WorkIcon />}
              primary="Careers"
              selected={sectionForLocation(location) === 'careers'}
              to="/careers/"
            />
            <ListItemLink
              key="contact"
              icon={<ContactSupportIcon />}
              primary="Contact"
              selected={sectionForLocation(location) === 'contact'}
              to="/contact/"
            />
          </List>
        </div>
      </Drawer>
    </NavWrapper>
  );
};

export const Navs: React.FunctionComponent<NavProps> = ({
  location,
  hideLogo = false,
}) => {
  return (
    <>
      <Hidden smDown>
        <NavExpanded location={location} hideLogo={hideLogo} />
      </Hidden>
      <Hidden smUp>
        <NavCollapse location={location} hideLogo={hideLogo} />
      </Hidden>
    </>
  );
};

const MenuBar = styled(AppBar)(({ theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.default,
  boxShadow: 'none',
}));

export const PrimaryNav: React.FunctionComponent<NavProps> = ({
  location,
  hideLogo = false,
}) => {
  return (
    <MenuBar position="static">
      <Navs location={location} hideLogo={hideLogo} />
    </MenuBar>
  );
};

export const PrimaryHeader: React.FunctionComponent<NavProps> = ({
  location,
  hideLogo = false,
}) => (
  <Container>
    <PrimaryNav location={location} hideLogo={hideLogo} />
  </Container>
);

export default PrimaryHeader;
