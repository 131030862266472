import { Reducer, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { SiteState, reducer } from './reducer';
import { persistVersion, persistMigrate } from './migrations';
import { PersistentState } from './RootState';
import { pick } from 'lodash';
import { useDispatch } from 'react-redux';
import { DocsState } from '../docs/store';

const whitelistTransform = createTransform((inboundState, key) => {
  if (key in PersistentState) {
    return pick(inboundState, PersistentState[key as string]);
  } else {
    return inboundState;
  }
});

const persistConfig = {
  key: 'onechronos',
  storage,
  version: persistVersion,
  migrate: persistMigrate,
  blacklist: ['site'],
  transforms: [whitelistTransform],
};

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: persistReducer(
    persistConfig,
    reducer as Reducer<{ site: SiteState; docs: DocsState }>
  ),
});

export type AppDispatch = typeof store.dispatch;

// Typescript workaround. Semantically, we need the type of
// useDispatch<AppDispatch>, but type erasure means that we end up with the bare
// type of useDispatch. Using this workaround allows us to give a proper return
// type signature to the exported module member.

// eslint-disable-next-line react-hooks/rules-of-hooks
const appDispatchTypeProxy = () => useDispatch<AppDispatch>();

export const useAppDispatch = (): ReturnType<typeof appDispatchTypeProxy> =>
  useDispatch<AppDispatch>();

export const persistor = persistStore(store);
